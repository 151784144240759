import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { LocationsService } from 'src/app/services/locations.service';

@Component({
  selector: 'app-add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.scss']
})
export class AddTransactionComponent implements OnInit {
  @Input() locationId;
  addTransactionForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private modalController: ModalController, private locationService: LocationsService ) { 
    this.addTransactionForm = this.formBuilder.group({
      transactionAmount: [''],
      accountHolderName: [''],
      accountNumber: [''],
      expirationDate: ['']
  });
  }

  ngOnInit() {
  }

  async cancelAdd() {
    await this.modalController.dismiss(false);
  }

  async addTransaction(locationId) {
    console.log('Submitting Transaction to location ' + locationId);
    var transactionBody = {
      'location_id' : locationId,
      'action':  'sale',
      'payment_method': 'cc',
      'transaction_amount': this.addTransactionForm.controls['transactionAmount'].value,
      'account_number': this.addTransactionForm.controls['accountNumber'].value,
      'account_holder_name': this.addTransactionForm.controls['accountHolderName'].value,
      'exp_date': this.addTransactionForm.controls['expirationDate'].value,
    };
    var request = {
      'transaction': transactionBody
    };

    this.locationService.postData('transactions', request).subscribe((data) => {
      console.log(data);
      this.modalController.dismiss(data);
    });
  }

}
