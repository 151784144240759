import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';

const apiHost = 'api.sandbox.payaconnect.com';
const apiNameSpace = 'v2';
const adminApiNameSpace = 'v2admin';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  loading;
  credentials;

  constructor(private http: HttpClient, public loadingController: LoadingController, private storage: Storage) { }

  async showLoading() {
    this.loading = await this.loadingController.create({
      duration: 2000
    });

    return await this.loading.present();
  }


getData(url) {
     return this.http.get(`https://${apiHost}/${apiNameSpace}/${url}`).pipe(
      tap(val => {
        if(this.loading) {
          this.loading.dismiss();
        }
        console.log(val);
        return val;
      })
    );
  }

  postData(url, request) {
    console.log('Post to ' + url);
    console.log(request + ' in postData method');
    return this.http.post(`https://${apiHost}/${apiNameSpace}/${url}`, request).pipe(
      tap(value => {
        if(this.loading) {
          this.loading.dismiss();
        }
        console.log(value);
      })
    );
  }

  postAdminData(url, request) {
    console.log('Post to ' + url);
    console.log(request + ' in postData method');
    return this.http.post(`https://${apiHost}/${adminApiNameSpace}/${url}`, request).pipe(
      tap(value => {
        if(this.loading) {
          this.loading.dismiss();
        }
        console.log(value);
      })
    );
  }

  // Get Credentials Helper
  getCredentials() {
    return {
      "userId": localStorage.getItem('user-id'),
      "userApiKey": localStorage.getItem('user-api-key'),
      "deverloperId": localStorage.getItem('developer-id'),
      "hashKey": localStorage.getItem('hash-key')
    };
  }
}
