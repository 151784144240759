import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private storage: Storage, public toastController: ToastController) { }

  // Intercept All HTTP Requests
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    console.log('Intercepting');
    var userId ;
    var userApiKey;
    var developerId = localStorage.getItem('developer-id');;

    if(request.url.includes("v2admin")) {
      console.log("Admin Request");
      userId = localStorage.getItem('location-admin-id');
      userApiKey = localStorage.getItem('location-admin-user-api-key');
    }

    else {
      console.log("General Request");
      userId = localStorage.getItem('user-id');
      userApiKey = localStorage.getItem('user-api-key');
    }

    if(userId) {
      request = request.clone({
        setHeaders: {
          'user-id': userId
        }
      });
    }

    if(userApiKey) {
      request = request.clone({
        setHeaders: {
          'user-api-key': userApiKey
        }
      });
    }

    if(developerId) {
      request = request.clone({
        setHeaders: {
          'developer-id': developerId
        }
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('In the Return');
          console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.error.success === false) {
            this.presentToast('Login failed');
          } else {
            //this.router.navigate(['login']);
          }
        }
        return throwError(error);
}));

  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top'
    });
    toast.present();
}

}
