import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { LocationsService } from 'src/app/services/locations.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {
  @Input() locationId;
  addContactForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private modalController: ModalController, private locationService: LocationsService) { 
    this.addContactForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      phone: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: ['']
  });
  }

  ngOnInit() {
  }

  async cancelAdd() {
    await this.modalController.dismiss(false);
  }

  async addContact(locationId) {
    console.log('Submitting Contact to location ' + locationId);
    var contactBody = {
      'location_id' : locationId,
      'first_name': this.addContactForm.controls['firstName'].value,
      'last_name': this.addContactForm.controls['lastName'].value,
      'email': this.addContactForm.controls['email'].value,
      'home_phone': this.addContactForm.controls['phone'].value,
      'city': this.addContactForm.controls['city'].value,
      'state': this.addContactForm.controls['state'].value,
      'zip': this.addContactForm.controls['zip'].value,
    };
    var request = {
      'contact': contactBody
    };

    this.locationService.postData('contacts', request).subscribe((data) => {
      console.log(data);
      this.modalController.dismiss(data);
    });
  }

}
