import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'locations',
    loadChildren: './list/list.module#ListPageModule'
  },
  { path: 'location/:id', loadChildren: './pages/location/location.module#LocationPageModule' },
  { path: 'transactions/:id', loadChildren: './pages/transactions/transactions.module#TransactionsPageModule' },
  { path: 'transaction/:id', loadChildren: './pages/transaction/transaction.module#TransactionPageModule' },
  { path: 'contacts/:locationId', loadChildren: './pages/contacts/contacts.module#ContactsPageModule' },
  { path: 'contact/:contactId', loadChildren: './pages/contact/contact.module#ContactPageModule' },
  { path: ':page/transactions/:contactId', loadChildren: './pages/transactions/transactions.module#TransactionsPageModule' },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: ':page/stored-accounts/:iD', loadChildren: './pages/stored-accounts/stored-accounts.module#StoredAccountsPageModule' },
  { path: 'stored-account/:storedAccountId', loadChildren: './pages/stored-account/stored-account.module#StoredAccountPageModule' },
  { path: 'hosted-payment-pages/:locationId', loadChildren: './pages/hosted-payment-pages/hosted-payment-pages.module#HostedPaymentPagesPageModule' },
  { path: 'services/:locationId', loadChildren: './pages/services/services.module#ServicesPageModule' },
  { path: 'service/:serviceId', loadChildren: './pages/service/service.module#ServicePageModule' },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
